import React from 'react'
import Seo from '../components/molecules/Seo'
import Container from '../components/atoms/Container'
import styled from 'styled-components'
import {graphql, Link} from 'gatsby'
import ProjectCard from '../components/organisms/ProjectCard'
import ArticleCard from '../components/organisms/ArticleCard'

const Holder = styled.div`
  svg {
    width: 4rem;
    height: auto;
    margin-right: 2rem;
  }
`

const Intro = styled.div`
  max-width: 17.5em;
  margin-bottom: 3em !important;

  > h2 {
    line-height: 1.25;
    ${(props) => props.theme.fluidType(3.5)};

    span {
      ${(props) => props.theme.fluidType(0)};
      text-decoration: underline;
      transition: color 300ms ease-in-out;

      &:hover {
        color: ${props => props.theme.colours.blue};
      }
    }
  }
`

const CardHolder = styled.div`
  border-top: 2px solid ${props => props.theme.colours.blue};

  > h3 {
    color: ${props => props.theme.colours.blue};
    margin-bottom: 3rem;

    &:hover {
      text-decoration: underline;
    }
  }
`

function IndexPage({data}) {
    const {
        title,
        description,
        author,
        ogDescription,
        ogTitle,
        ogImage,
        ogUrl,
        introStatement,
        project
    } = data.contentfulHomepage

    const {nodes} = data.allContentfulArticle

    return (
        <Holder>
            <Seo title={title} description={description} author={author} ogDescription={ogDescription} ogTitle={ogTitle}
                 ogImage={ogImage} ogUrl={ogUrl}/>
            <Container>
                <Intro>
                    <h2>{introStatement}{' '}<span><Link to="/what-we-do/">Find out more</Link></span></h2>
                </Intro>
                <CardHolder>
                    <h3><Link to="/projects">Projects</Link></h3>
                    {project && project.map((item, index) => {
                        return <ProjectCard key={index} link={'/projects/' + item.slug} image={item.excerptImage}
                                            lede={item.lede}
                                            title={item.title}/>
                    })}
                </CardHolder>
                <CardHolder>
                    <h3><Link to="/journal">Journal</Link></h3>
                    {nodes && nodes.map((item, index) => {
                        return <ArticleCard image={item.excerptImage} lede={item.lede} link={item.slug}
                                            title={item.title} key={index}/>
                    })}
                    <h3><Link to="/journal">More articles</Link></h3>
                </CardHolder>
            </Container>
        </Holder>
    )
}

export const query = graphql`
  query homepageQuery {
    contentfulHomepage {
      title
      description
      author
      ogTitle
      ogDescription
      ogUrl
      ogImage {
        gatsbyImageData
        title
        description
      }
      introStatement
      project {
        slug
        excerptImage {
            gatsbyImageData
            title
            description
        }
        title
        lede
      }
    }
    allContentfulArticle(limit: 2, sort: {fields: date, order: DESC}) {
        nodes {
          excerptImage {
            gatsbyImageData
            title
            description
          }
          title
          lede
          slug
        }
    }
  }
`

export default IndexPage
