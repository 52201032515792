import React from 'react'
import styled from 'styled-components'
import {GatsbyImage} from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

const Holder = styled.div`
  margin-bottom: 5rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 8rem;
  }

  > a {
    text-decoration: none;
    transition: color 300ms ease-in-out;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      color: ${props => props.theme.colours.blue};
    }
  }
`

const Lede = styled.p`
  width: initial;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: 50%;
  }
`

ProjectCard.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    lede: PropTypes.object.isRequired,
    key: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
}

function ProjectCard({image, title, lede, key, link}) {
    return (
        <Holder key={key}>
            <Link to={link}>
                <GatsbyImage alt={image.title} image={image.gatsbyImageData}/>
                <h1>{title}</h1>
            </Link>
            <Lede>{lede}</Lede>
        </Holder>
    )
}

export default ProjectCard