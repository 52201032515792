import React from 'react'
import {Link} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Holder = styled.div`
  a {
    text-decoration: none;
    transition: color 300ms ease-in-out;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    margin-bottom: 2rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: grid;
      grid-template-columns: 3fr 1fr;
      margin-bottom: 3rem;
    }

    &:hover h2 {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      color: ${props => props.theme.colours.blue};
    }
  }

  .gatsby-image-wrapper {
    object-fit: cover;
    max-height: 180px;
    margin-bottom: 0.5rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      max-height: unset;
      margin-bottom: 0;
    }
  }

  h2 {
    font-family: 'Soehne';
    margin-top: 0;
    transition: all 300ms ease-in-out;
  }

`

ArticleCard.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    lede: PropTypes.object.isRequired,
    key: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired
}

function ArticleCard({image, title, lede, key, link}) {
    return (
        <Holder key={key}>
            <Link to={'/journal/' + link}>
                <div>
                    <h2>{title}</h2>

                    <p>{lede}</p>
                </div>
                <GatsbyImage alt={image.title} image={image.gatsbyImageData}/>
            </Link>
        </Holder>
    )
}

export default ArticleCard